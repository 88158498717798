<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <Crumbs> </Crumbs>
    <div class="fullScreenMain">
      <el-tabs v-model="activeName" class="fullScreenMainHeader">
        <el-tab-pane name="0" label="商务团队分工"></el-tab-pane>
        <el-tab-pane name="1" label="基本信息" v-if="Dispose_data.id"></el-tab-pane>
        <el-tab-pane name="2" label="附件" v-if="Dispose_data.id"></el-tab-pane>
        <el-tab-pane name="3" label="商务追踪" v-if="Dispose_data.id"></el-tab-pane>
        <el-tab-pane name="4" label="商务绩效" v-if="Dispose_data.id"></el-tab-pane>
      </el-tabs>

      <div class="fullScreenMainCon" v-loading="loading">
        <template v-if="businessInformation.principalUserName && activeName == 0">
          <el-descriptions
            style="margin-top: 10px; margin-bottom: 10px"
            title="商务基础信息"
            direction="vertical"
            :column="5"
            border
          >
            <el-descriptions-item label="商务名称" :span="2">
              {{ businessInformation.businessName }}
            </el-descriptions-item>
            <el-descriptions-item label="项目来源" :span="1">
              {{ businessInformation.projectSource | dict(dictData.projectSource) }}
            </el-descriptions-item>
            <el-descriptions-item label="商务类型" :span="1">
              {{ businessInformation.businessType | dict(dictData.businessType) }}
            </el-descriptions-item>
            <el-descriptions-item label="业务种类" :span="1">
              {{ businessInformation.businessLines | dict(dictData.businessLines) }}
            </el-descriptions-item>

            <el-descriptions-item label="业主单位" :span="3">
              {{ businessInformation.ownerUnit }}
            </el-descriptions-item>
            <el-descriptions-item label="项目承接公司" :span="1">
              {{ businessInformation.companyType | dict(dictData.companyType) }}
            </el-descriptions-item>
            <el-descriptions-item label="实际商务负责人" :span="1">
              {{ businessInformation.principalUserName | addDept(CompanyMembers) }}
            </el-descriptions-item>
          </el-descriptions>
        </template>

        <el-table
          ref="multipleTable"
          border
          :data="table"
          :default-expand-all="true"
          tooltip-effect="dark"
          max-height="600"
          height="string"
          v-show="activeName == 0"
          v-loading="loading"
        >
          <el-table-column
            align="center"
            prop="content"
            label="工作内容"
            width="230"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="participatingDistributor"
            label="参与角色"
            width="250"
          ></el-table-column>
          <el-table-column align="center" label="参与分配方" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <!-- 分配对象为部门 -->
              <el-table
                v-if="
                  scope.row.ruleCode == 'PROJECT_IMPLEMENTATION' ||
                  scope.row.ruleCode == 'CONSULT_BIDDING_SCHEME'
                "
                ref="multipleTable"
                :key="once1"
                border
                height="string"
                max-height="200"
                v-show="scope.row.fp.length > 0"
                :data="scope.row.fp"
                tooltip-effect="dark"
              >
                <el-table-column
                  align="center"
                  type="index"
                  label="序号"
                  :show-overflow-tooltip="false"
                ></el-table-column>
                <el-table-column align="center" prop="deptName" label="部门名称">
                  <!-- <template slot-scope="scopes">
                    {{ scopes.row.deptId | cascader(deptData, 'id', 'deptName') }}
                  </template> -->
                </el-table-column>
                <el-table-column prop="title" align="center" label="操作">
                  <template slot-scope="scopes">
                    <el-button
                      type="text"
                      class="text_Remove_Bgc"
                      size="small"
                      @click.stop="del(scopes, scope)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分配方式为用户 -->
              <el-table
                v-else
                ref="multipleTable"
                :key="once"
                border
                height="string"
                max-height="200"
                v-show="scope.row.fp.length > 0"
                :data="scope.row.fp"
                tooltip-effect="dark"
                id="applySelectTable"
              >
                <el-table-column
                  align="center"
                  type="index"
                  label="序号"
                  :show-overflow-tooltip="false"
                ></el-table-column>
                <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                <!-- <el-table-column
                      align="center"
                      label="商务经理"
                      v-if="scope.row.ruleCode == 'BUSINESS_FOLLOW_UP'">
                      <template slot-scope="scopes">
                        <el-checkbox
                          v-model="scopes.row.businessManagerSign"
                          @change="
                            onBusinessManagerBoxChange(scope.row, scopes.row, $event)
                          "></el-checkbox>
                      </template>
                    </el-table-column> -->
                <el-table-column align="center" prop="deptNames" label="部门"></el-table-column>
                <el-table-column align="center" prop="postName" label="岗位"></el-table-column>
                <el-table-column
                  align="center"
                  prop="phone"
                  label="联系电话"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="title"
                  align="center"
                  v-if="scope.row.ruleCode !== 'LEAD_COMMUNICATION'"
                  label="操作"
                >
                  <template slot-scope="scopes">
                    <el-button
                      type="text"
                      class="text_Remove_Bgc"
                      size="small"
                      @click.stop="del(scopes, scope)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column align="center" label="" width="100">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                class="text_Edit_Bgc"
                v-if="
                  scope.row.ruleCode == 'PROJECT_IMPLEMENTATION' ||
                  scope.row.ruleCode == 'CONSULT_BIDDING_SCHEME'
                "
                @click.stop="checkDept(scope)"
                >选择部门</el-button
              >
              <el-button
                type="text"
                size="small"
                class="text_Edit_Bgc"
                v-else-if="
                  scope.row.ruleCode !== 'OTHER' && scope.row.ruleCode !== 'LEAD_COMMUNICATION'
                "
                @click.stop="checkUser(scope)"
                >选择用户</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Manage width="600px" :BusinessId="Dispose_data.id" v-show="activeName == 1"></Manage>
        <Annex :manageId="Dispose_data.id" :file_Data="file_Data" v-show="activeName == 2"></Annex>
        <Business width="600px" :BusinessId="Dispose_data.id" v-show="activeName == 3"></Business>
        <DistriBution
          width="600px"
          :Disabled="true"
          :options="{ businessManageId: Dispose_data.id }"
          v-if="activeName == 4"
        ></DistriBution>
      </div>

      <div class="fullScreenOperation">
        <el-button type="success" plain v-show="activeName == 0" @click="save">保存</el-button>
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>

    <el-dialog :visible.sync="showEdit" :before-close="cancelSelect" append-to-body width="65%">
      <div slot="title" style="color: #409eff">新增用户</div>
      <div class="mainList_operation_search">
        <DeptCascader
          style="width: 30%"
          :placeholder="'请选择部门'"
          :deptList="deptList"
          v-model="parameter.deptId"
        />

        <el-input
          class="mainList_operation_search_Name"
          style="width: 200px; margin: 0 8px 8px 8px"
          placeholder="姓名"
          clearable
          v-model="parameter.userName"
        ></el-input>
        <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)"
          >搜索</el-button
        >
        <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
      </div>
      <el-table
        v-loading="selectedListLoad"
        :data="selectedList"
        style="width: 100%"
        max-height="400"
        border
        id="selectedTable"
        :row-key="getRowKey"
        @select="selectSingle"
        @selection-change="handleCurrentChange"
        ref="selectedTable"
      >
        <el-table-column
          type="selection"
          label="选择"
          :reserve-selection="true"
          :selectable="selectEnable"
          width="60"
        >
        </el-table-column>
        <el-table-column align="center" prop="deptNames" label="部门"> </el-table-column>
        <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
      </el-table>
      <div style="float: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChangeHandler"
          :current-page="parameter.pageNow"
          :page-size="parameter.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="total, prev, pager, next, slot, jumper"
          :total="parameter.total"
        >
          <span class="el-pagination__jump e_a_pagination">
            <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
            <span style="padding-top: 1px">条/页</span>
          </span>
        </el-pagination>
      </div>
      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button type="success" style="min-width: 120px; margin: 0 25px" @click="saveSelectedFn"
          >确 定</el-button
        >
        <el-button
          type="warning"
          style="min-width: 120px; margin: 0 25px"
          plain
          @click="cancelSelect"
          >取 消</el-button
        >
      </span>
    </el-dialog>

    <!-- 选择部门 -->
    <!-- <EditDialog width="600px" :isShow.sync="showDeptEdit" @submit="submitDept">
      <div slot="title" class="title">选择部门</div>
      <div slot="content" class="content">
        <el-checkbox-group class="dept-select" v-model="arrDeptSelect">
          <el-checkbox
            class="dept-select-item"
            v-for="item in this.userList"
            :key="item.id"
            :label="item.deptId"
          >
            <i class="iconfont iconcompany" style="color: #399ffb; margin-right: 0px"></i>
            {{ item.deptId | cascader(deptData, 'id', 'deptName') }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </EditDialog> -->

    <DeptTreeSelectDialog
      v-if="showDeptEdit"
      :isDeptTreeShow.sync="showDeptEdit"
      :selectedTreeData="arrDeptSelect"
      @getDeptList="submitDept"
    ></DeptTreeSelectDialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Business: () => import('@/views/publicView/Business.vue'),
    Annex: () => import('@/views/publicView/Annex.vue'),
    Manage: () => import('@/views/publicView/Manage.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
    DeptTreeSelectDialog: () => import('@/components/dept/DeptTreeSelectDialog.vue'),
  },

  props: {
    showEditDialog: {
      type: Boolean,
      default: false,
    },
    Dispose_data: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      showEdit: false,
      loading: false,
      submitLoading: false,
      type: 'portrait',
      tableData: [], //全部用户数据
      // userList: [],
      editDialogLoading: false,
      del_table: [],
      // deptData: [],
      arrSelect: [], //表示当前表格中已选择的用户
      arrDeptSelect: [], //标识用户选择部门
      tb_id: [],
      table: [],
      index: 0,
      xh_i: 0,
      content1: [
        {
          content: '负责关键沟通与组织',
          participatingDistributor: '商务负责人',
          ruleCode: 'LEAD_COMMUNICATION',
          fp: [],
        },
        {
          content: '提供有效项目信息',
          participatingDistributor: '信息提供人',
          ruleCode: 'PROVIDE_INFORMATION',
          fp: [],
        },
        {
          content: '商务跟进',
          participatingDistributor: '商务助理',
          ruleCode: 'BUSINESS_FOLLOW_UP',
          fp: [],
        },
        {
          content: '有效引荐',
          participatingDistributor: '引荐人',
          ruleCode: 'REFERRER',
          fp: [],
        },
        {
          content: '售前和投标技术方案编制',
          participatingDistributor: '售前和投标技术方案编制部门',
          ruleCode: 'CONSULT_BIDDING_SCHEME',
          fp: [],
        },
        { content: '其他', participatingDistributor: '公司', ruleCode: 'OTHER', fp: [] },
      ],
      content2: [
        {
          content: '负责关键沟通与组织',
          participatingDistributor: '商务负责人',
          ruleCode: 'LEAD_COMMUNICATION',
          fp: [],
        },
        {
          content: '提供有效项目信息',
          participatingDistributor: '信息提供人',
          ruleCode: 'PROVIDE_INFORMATION',
          fp: [],
        },
        {
          content: '商务跟进',
          participatingDistributor: '商务助理',
          ruleCode: 'BUSINESS_FOLLOW_UP',
          fp: [],
        },
        { content: '有效引荐', participatingDistributor: '引荐人', ruleCode: 'REFERRER', fp: [] },
        {
          content: '售前和投标技术方案编制',
          participatingDistributor: '售前和投标技术方案编制部门',
          ruleCode: 'CONSULT_BIDDING_SCHEME',
          fp: [],
        },
        { content: '其他', participatingDistributor: '公司', ruleCode: 'OTHER', fp: [] },
      ],
      content3: [
        {
          content: '负责关键沟通与组织',
          participatingDistributor: '商务负责人',
          ruleCode: 'LEAD_COMMUNICATION',
          fp: [],
        },
        {
          content: '提供有效项目信息',
          participatingDistributor: '信息提供人',
          ruleCode: 'PROVIDE_INFORMATION',
          fp: [],
        },
        {
          content: '商务跟进',
          participatingDistributor: '商务助理',
          ruleCode: 'BUSINESS_FOLLOW_UP',
          fp: [],
        },
        {
          content: '有效引荐（历史商务关系)',
          participatingDistributor: '引荐人',
          ruleCode: 'HISTORICAL_BUSINESS_REFERRER',
          fp: [],
        },
        {
          content: '售前和投标技术方案编制',
          participatingDistributor: '售前和投标技术方案编制部门',
          ruleCode: 'CONSULT_BIDDING_SCHEME',
          fp: [],
        },
        {
          content: '驻场或原项目实施',
          participatingDistributor: '驻场团队或原实施团队所在部门',
          ruleCode: 'PROJECT_IMPLEMENTATION',
          fp: [],
        },
        { content: '其他', participatingDistributor: '公司', ruleCode: 'OTHER', fp: [] },
      ],
      activeName: 0,
      file_Data: [],
      once: 0, //element的bug，需要更新div的key 表单高度才能正常显示
      once1: 0,
      dictData: {
        businessType: [],
        businessDisbursenentType: [],
        projectSource: [],
        businessLines: [],
      },
      showDeptEdit: false, //控制选择部门显示与隐藏
      parameter: {
        deptId: null,
        userName: null,
        pageNow: 1,
        total: 1,
        pageSize: 50,
      },
      pageSize: 0,
      selectedListLoad: false,
      selectedList: [],
      deptList: [],
      temporaryList: [],
    }
  },
  inject: ['re'],
  mounted() {},
  computed: {
    ...mapState({
      businessInformation: state => state.business.businessInformation,
      userAssignmentMap: state => state.business.userAssignmentMap,
      CompanyMembers: state => state.business.CompanyMembers,
      userInfo: state => state.user.userInfo,
      selectDeptList: state => state.business.selectDeptList,
    }),
  },
  watch: {
    Dispose_data: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        if (newVal.hasOwnProperty('id') && newVal.id) {
          this.getData()
        }
      },
    },
  },
  filters: {
    // 添加部门前缀
    addDept(val, n) {
      let name = ''
      if (n && n.length > 0) {
        for (let index = 0; index < n.length; index++) {
          const v = n[index]
          if (v.userName == val) {
            var newArr = v.departmentName.split(',')
            name = v.userName + ' - ' + newArr[0]
            break
          }
        }
      } else {
        name = val
      }
      return name
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeFun()
      // this.resizeFun窗口变化执行的方法
      window.addEventListener('resize', this.resizeFun)
    })
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    if (this.selectDeptList.length <= 0) {
      this.getDeptList()
    } else {
      this.deptList = this.selectDeptList
    }
    // this.getUserList()
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.dictData.projectSource = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_LINES', true)
      .then(res => {
        this.dictData.businessLines = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    getRowKey(row) {
      return row.id
    },
    cancelSelect() {
      this.showEdit = false
      this.temporaryList = []
    },
    selectEnable(row, rowIndex) {
      if (this.arrSelect.indexOf(row.id) != -1) {
        return false
      }
      return true
    },
    selectSingle(selection, row) {
      // this.$refs.selectedTable.toggleRowSelection(row,true)
    },
    saveSelectedFn() {
      let arr = []
      this.temporaryList.forEach(v => {
        if (this.arrSelect.indexOf(v.id) == -1) {
          arr.push(v)
        }
      })
      let a = {}
      arr.forEach(e => {
        a = {}
        a.userType = 'MEMBER'
        a.businessId = this.Dispose_data.id
        a.staffId = e.id
        a.postName = e.postName
        a.deptNames = e.deptNames
        a.userName = e.userName
        a.phone = e.phone
        a.businessManagerSign = false
        a.workContent = this.table[this.index].content
        a.ruleCode = this.table[this.index].ruleCode
        if (this.table[this.index].fp instanceof Array) {
          this.$set(this.table, this.index, this.table[this.index])
        } else {
          this.table[this.index].fp = []
          this.$set(this.table, this.index, this.table[this.index])
        }
        this.table[this.index].fp.push(a)
        this.tableData.push(a)
      })
      this.once += 1
      this.showEdit = false
      this.temporaryList = []
    },
    handleCurrentChange(val) {
      this.temporaryList = val
    },
    getData() {
      this.loading = true
      this.$api.businessManage
        .getUserAssignment(this.Dispose_data.id)
        .then(res => {
          if (this.Dispose_data.businessType == 'WSQGTJBLXM') {
            this.table = this.content1.deepClone()
          } else if (this.Dispose_data.businessType == 'YSQGTXKHXM')
            this.table = this.content2.deepClone()
          else {
            //YSQGTLKHXM
            this.table = this.content3.deepClone()
          }

          if (res.data.userAssignment == null) {
            this.tableData = []
          } else {
            let arr = res.data.userAssignment
            let new_arr = []
            arr.forEach(e => {
              // 将商务经理字段做修改
              if (e.ruleCode == 'BUSINESS_FOLLOW_UP') {
                e.businessManagerSign = e.businessManagerSign == 'HAVE' ? true : false
              }
              this.table.forEach(i => {
                if (i.ruleCode == e.ruleCode) {
                  i.fp.push(e)
                }
              })

              new_arr.push(e)
            })
            this.tableData = new_arr
          }
          this.once += 1
          this.once1 += 1
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })

      this.$api.businessManage
        .getBusinessManage(this.Dispose_data.id)
        .then(res => {
          this.editDialogLoading = false

          let arr = []
          if (res.data.businessFileList.length > 0) {
            for (let i = 0; i < res.data.businessFileList.length; i++) {
              const e = res.data.businessFileList[i]
              e.attachmentName = e.fileName
              e.attachmentPath = e.filePath
              arr.push(e)
            }
          }
          this.file_Data = arr
        })
        .catch(err => {
          this.editDialogLoading = false
          console.log(err)
        })
    },
    del(scopes, scope) {
      this.arrSelect = this.arrSelect.filter(v => v != scopes.row.staffId)
      this.table[scope.$index].fp.splice(scopes.$index, 1)
      this.tableData.forEach((v, i) => {
        if (v.staffId == scopes.row.staffId) {
          this.tableData.splice(i, 1)
        }
      })
      if (scopes.row.id) {
        this.del_table.push(scopes.row.id)
      }
    },
    submit() {
      var arr = this.$refs.principalUserDataTree.getCheckedNodes(true)
      arr = arr.filter(item => !this.arrSelect.some(x => item.id == x))
      let a = {}
      arr.forEach(e => {
        a = {}
        a.userType = 'MEMBER'
        a.businessId = this.Dispose_data.id
        a.staffId = e.id
        a.postName = e.postName
        a.deptNames = e.deptNames
        a.userName = e.userName
        a.phone = e.phone
        a.businessManagerSign = false
        a.workContent = this.table[this.index].content
        a.ruleCode = this.table[this.index].ruleCode
        if (this.table[this.index].fp instanceof Array) {
          this.$set(this.table, this.index, this.table[this.index])
        } else {
          this.table[this.index].fp = []
          this.$set(this.table, this.index, this.table[this.index])
        }
        this.table[this.index].fp.push(a)
        this.tableData.push(a)
      })
      this.once += 1
      this.showEdit = false
    },
    save() {
      let arr = []
      for (let i = 0; i < this.tableData.length; i++) {
        const e = this.tableData[i]
        // if (e.userType == '')
        //   arr.push({
        //     id: e.id,
        //     staffId: e.staffId,
        //     businessId: this.Dispose_data.id,
        //     userType: 'MEMBER',
        //     workContent: e.workContent,
        //     ruleCode: e.ruleCode,
        //   })
        // 修改商务经理字段值
        if (e.ruleCode == 'BUSINESS_FOLLOW_UP') {
          e.businessManagerSign = e.businessManagerSign ? 'HAVE' : 'NOT'
        } else {
          e.businessManagerSign = 'NOT'
        }
        arr.push(e)
      }
      let obj = {
        userAssignmentRequst: arr,
        deleteUserAssignmentList: this.del_table,
      }
      this.$api.businessManage
        .addUserAssignment(obj)
        .then(res => {
          this.editDialogLoading = false
          this.del_table = []
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          this.editDialogLoading = false
          console.log(err)
        })
    },
    // getUserList() {
    //   this.$api.sysDept.listDept({}).then(res => {
    //     this.deptData = res.data
    //     res.data.some(r => {
    //       this.userList.push({
    //         deptId: r.id,
    //         label: r.userName,
    //         icon: 'iconcompany',
    //         type: 'dept',
    //         disabled: true,
    //       })
    //     })
    //   })
    // },
    // loadNode(node, resolve) {
    //   if (node.level === 0) {
    //     resolve(this.userList)
    //   } else {
    //     if (node.data.type == 'dept') {
    //       this.$api.user
    //         .listStaffNotPage({
    //           deptId: node.data.deptId,
    //         })
    //         .then(res => {
    //           var arr = []
    //           res.data.some(r => {
    //             arr.push(
    //               r.combination({
    //                 leaf: true,
    //                 deptId: node.data.deptId,
    //               })
    //             )
    //           })
    //           resolve(arr)
    //         })
    //         .catch(err => {})
    //     } else {
    //       resolve([])
    //     }
    //   }
    // },
    checkUser(scope) {
      // 如果当前打开
      this.parameter.userName = null
      this.parameter.deptId = null
      this.parameter.pageNow = 1
      this.getSelectedList()
      this.index = scope.$index
      // 展开树形选择器时勾选已有的值
      this.arrSelect = []
      if (scope.row.fp) {
        this.arrSelect = scope.row.fp.map(item => item.staffId)
      }
      this.showEdit = true
      this.selectedListLoad = true
      this.$nextTick(() => {
        this.selectedList.forEach(v => {
          if (this.arrSelect.indexOf(v.id) != -1) {
            this.$refs.selectedTable.toggleRowSelection(v, true)
          } else {
            this.$refs.selectedTable.toggleRowSelection(v, false)
          }
        })
        this.selectedListLoad = false
      })
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resizeFun)
    },
    resizeFun() {},

    checkDept(scope) {
      // 如果当前打开
      this.index = scope.$index
      // 展开树形选择器时勾选已有的值
      this.arrDeptSelect = []
      if (scope.row.fp) {
        this.arrDeptSelect = scope.row.fp.map(item => item.deptId)
      }
      this.showDeptEdit = true
    },

    submitDept(val) {
      let a = {}
      let arr = []
      if (this.table[this.index].fp instanceof Array) {
        arr = val.filter(item => !this.table[this.index].fp.some(x => item.id == x.deptId))
      }
      arr.forEach(e => {
        a = {}
        a.userType = 'DEPT'
        a.businessId = this.Dispose_data.id
        a.deptId = e.id
        a.deptName = e.deptName
        a.workContent = this.table[this.index].content
        a.ruleCode = this.table[this.index].ruleCode
        if (this.table[this.index].fp instanceof Array) {
          this.$set(this.table, this.index, this.table[this.index])
        } else {
          this.table[this.index].fp = []
          this.$set(this.table, this.index, this.table[this.index])
        }
        this.table[this.index].fp.push(a)
        this.tableData.push(a)
      })
      this.once1 += 1
      this.showDeptEdit = false
    },

    // 当商务经理复选框勾选时触发
    onBusinessManagerBoxChange(line, item, e) {
      if (e) {
        if (line.fp && line.fp.length > 0) {
          const findResult = line.fp.find(item => item.businessManagerSign)
          if (findResult) {
            line.fp.forEach(item => (item.businessManagerSign = false))
            item.businessManagerSign = e
          }
        }
      }
    },

    handleReset() {
      this.parameter.userName = null
      this.parameter.deptId = null
      this.parameter.pageNow = 1
      this.getSelectedList()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getSelectedList()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getSelectedList()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getSelectedList()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 获取所有人员
    getSelectedList() {
      this.selectedListLoad = true
      this.$api.user
        .listStaff(this.parameter)
        .then(res => {
          this.selectedList = res.data?.records.deepClone()
          this.parameter.total = res.data?.total
          this.$nextTick(() => {
            this.selectedList.forEach(v => {
              if (this.arrSelect.indexOf(v.id) != -1) {
                this.$refs.selectedTable.toggleRowSelection(v, true)
              }
            })
          })
          this.selectedListLoad = false
        })
        .catch(err => {
          console.log(err)
          this.selectedListLoad = false
        })
    },
    //部门
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {})
    },
  },
}
</script>

<style scoped lang="scss">
#selectedTable {
  ::v-deep .el-table__header {
    .el-checkbox {
      display: none;
    }
  }
}
.p_t_35 {
  padding-top: 35px;
}
.p_b {
  padding-bottom: 25px;
}
.el-table {
  height: calc(100vh - 347px);
}

.content {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  height: calc(100% - 3rem);
}

.dept-select {
  display: flex;
  flex-direction: column;
  .dept-select-item {
    height: 26px;
    line-height: 26px;
  }
}
</style>
